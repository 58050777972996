<template>
  <el-dialog
      :title="params.title"
      :model-value="params.show"
      @close="onCacel"
      width="800px" center>
    <el-form :model="form" label-width="120px" class="ruleForm">
      <div style="display: grid; grid-template-columns: repeat(2, 50%);" >

        <el-form-item label="客户名称" prop=" compName">
         <el-input :modelValue="form.compName" readonly></el-input>
        </el-form-item>

        <el-form-item label="联系人姓名" prop="contactPerson">
         <el-input :modelValue="form.contactPerson" readonly></el-input>
        </el-form-item>

        <el-form-item label="联系电话" prop="contactPhone">
         <el-input :modelValue="form.contactPhone" readonly></el-input>
        </el-form-item>

        <el-form-item label="客户地址" prop="compAddress">
         <el-input :modelValue="form.compAddress" readonly></el-input>
        </el-form-item>
      </div>
      <el-form-item>
        <el-button @click="onCacel()">返回</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

import axios from '@/utils/axios'
import {ElMessage} from 'element-plus'


export default {
  name: "Form",
  // 继承父窗体列表页的数据
  props: {
    params: Object
  },
  data() {
    return {
      // 全局变量，用于获得配置信息等
      global: window.global,

      // 更多选项
      option: {
        
      },
      // 验证选项
      rules: {},
    };
  },
  components: {
    
  },
  computed: {
    // 由于子组件不能修改继承来的对象，所以重新复制一份
    form: function () {
      var formData = this.params.data
      
      return formData
    }
  },
  created() {
    
  },
  // 父组件中继承过来的事件
  emits: ["onClose","getTableData"],
  methods: {
    
    // 返回事件
    onCacel() {
      this.$emit('onClose')
    }
  }
}
</script>